.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Competition-emblem {
  height: 20vmin;
}

.Competitions-show {
  background-color: white;
  border-radius: 15;
}

.App-header {
  background-color: #282c34;
  text-align: center;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: sans-serif;
  color: white;
  line-height: 1.5;
}

.App-content {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  font-family: sans-serif;
  color: white;
  line-height: 1.5;
}

.App-link {
  color: #61dafb;
}
